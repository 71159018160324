import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import IconLeft from "stelar/components/Icon/IconLeft";
import IconRight from "stelar/components/Icon/IconRight";
import { useTrackWhenViewed } from "shared/utils/useTrackWhenViewed";
import styles from "./StickyBanner.module.css";
import { trackFoldInteraction } from "./helper";
import {
  stickyBannerVisible,
  setStickyBannerVisible,
} from "../../utils/bannerHelper";

const cx = classnames.bind(styles);

function StickyBanner({ ...props }) {
  if (!props?.banner) {
    return null;
  }
  const { banner, onLinkClick, handleTracking } = props;
  const { altText, image, label, link, tracking } = banner;
  const [showBanner, setShowBanner] = React.useState(false);
  const { ref } = useTrackWhenViewed(tracking);

  // TODO implement ab-test
  const abTestWithInputField = false;

  React.useEffect(() => {
    const isBannerVisible = stickyBannerVisible();
    setStickyBannerVisible(isBannerVisible);
    setShowBanner(isBannerVisible);
  }, []);

  function handleChange() {
    setStickyBannerVisible(!showBanner);
    setShowBanner(!showBanner);
    handleTracking(trackFoldInteraction(!showBanner, tracking));
  }

  function handleClick(evt) {
    evt.preventDefault();
    setStickyBannerVisible(false);
    setShowBanner(false);
    onLinkClick(evt, tracking);
  }

  return (
    label &&
    image && (
      <div
        id="stickyBannerRoot"
        data-test-sell-stickybanner-root
        className={cx("stickybannerRoot", abTestWithInputField && "wide")}
      >
        <div className={cx("stickybannerContainer")}>
          <div className={cx("labelContainer")}>
            <button
              type="button"
              onClick={handleChange}
              className={cx("stickybannerLabelSide")}
              data-test-sell-stickybanner-toggle-label
            >
              <span className={cx("arrow")}>
                {showBanner ? <IconRight small /> : <IconLeft small />}
              </span>
              <span className={cx("labelText")}>{label}</span>
            </button>
          </div>

          <div
            className={cx(
              "bannerContent",
              showBanner ? "open" : "close",
              abTestWithInputField && "wide"
            )}
          >
            {/* TODO a11y */}
            {/* eslint-disable jsx-a11y/control-has-associated-label */}
            <button
              ref={ref}
              type="button"
              onClick={handleChange}
              className={cx("buttonXIcon", showBanner ? "open" : "close")}
              data-test-sell-stickybanner-toggle-icon
            >
              <span>
                <svg
                  viewBox="0 0 24 24"
                  className={cx("stickybannerCloseIcon")}
                >
                  <path d="M13.414 12L23.707 1.707A.999.999 0 1022.293.293L12 10.586 1.707.293A.999.999 0 10.293 1.707L10.586 12 .293 22.293a.999.999 0 101.414 1.414L12 13.414l10.293 10.293a.997.997 0 001.414 0 .999.999 0 000-1.414L13.414 12z" />
                </svg>
              </span>
            </button>
            {/* eslint-enable jsx-a11y/control-has-associated-label */}
            <a
              href={link}
              className={cx("bannerImageLink")}
              onClick={handleClick}
              data-test-sell-stickybanner-link
            >
              <img
                className={cx("bannerImage")}
                src={image}
                width={abTestWithInputField ? "280" : "220"}
                alt={altText}
              />
            </a>
            {/* {abTestWithInputField && (
              <div className={cx("newsletterWrapper")}>
                <Include
                  name="ase-newsletter"
                  layout="banner"
                  point="stickybanner"
                />
              </div>
            )} */}
          </div>
        </div>
      </div>
    )
  );
}

StickyBanner.propTypes = {
  altText: PropTypes.string,
  banner: PropTypes.object,
  image: PropTypes.string,
  isBannerActive: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  onLinkClick: PropTypes.func,
  handleTracking: PropTypes.func,
};

export default StickyBanner;
