import React, { useEffect } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import track from "shared/tracking";
import Message from "stelar/components/Message";

import { createHandleClick } from "shared/utils/routing";
import ContentKurator, {
  SkeletonContentKurator,
} from "../../../components/ContentKurator";
import StickyBanner from "../../../components/StickyBanner";

import s from "./Homepage.module.css";

function Homepage({
  head = {},
  banner,
  message,
  kuratorContentsTop,
  kuratorContentsBottom,
  routes,
  onImageLoad,
  loadedImages,
  loading,
  sliderState,
  sliderActions,
  closeOrOpenContentAccordionItem,
  openedContentAccordionItems,
  setTrackingListType,
  loadHomepage,
}) {
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        loadHomepage();
      }
    };
    window.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  const handleClick = createHandleClick(routes);

  function handleClickAndTracking(e, trackingData) {
    setTrackingListType(trackingData);
    handleClick(e);
    // ToDo: we have to change tracking structure | SELL-4957
    if (Array.isArray(trackingData)) {
      trackingData.forEach((data) => {
        track(data);
      });
    } else {
      track(trackingData);
    }
  }

  function handleTracking(data) {
    track(data);
  }

  const kuratorContentProps = {
    onLinkClick: handleClickAndTracking,
    loadedImages,
    onImageLoad,
    sliderState,
    sliderActions,
    closeOrOpenContentAccordionItem,
    openedContentAccordionItems,
    fullWidth: true,
    disableSpacings: true,
  };

  return (
    <div data-test-sell-homepage-page>
      <Helmet {...head} />
      <div className={s.contents}>
        {message && (
          <div className={s.message}>
            <Message {...message} />
          </div>
        )}
        {banner ? (
          <StickyBanner
            banner={banner}
            handleTracking={handleTracking}
            onLinkClick={handleClickAndTracking}
          />
        ) : null}
        {kuratorContentsTop
          ? kuratorContentsTop.map((content) => (
              <ContentKurator
                loading={loading}
                content={content}
                {...kuratorContentProps}
                key={`ContentKurator_top_${content && content.id}`}
              />
            ))
          : null}
        {kuratorContentsBottom
          ? kuratorContentsBottom.map((content) => (
              <ContentKurator
                loading={loading}
                content={content}
                {...kuratorContentProps}
                key={`ContentKurator_bottom_${content && content.id}`}
              />
            ))
          : null}
        {!kuratorContentsTop && !kuratorContentsBottom ? (
          <SkeletonContentKurator />
        ) : null}
      </div>
    </div>
  );
}

Homepage.propTypes = {
  banner: PropTypes.object,
  head: PropTypes.object,
  message: PropTypes.object,
  kuratorContent: PropTypes.array,
  kuratorContentsTop: PropTypes.array,
  kuratorContentsBottom: PropTypes.array,
  routes: PropTypes.array,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  loading: PropTypes.bool,
  sliderState: PropTypes.object,
  sliderActions: PropTypes.object,
  setTrackingListType: PropTypes.func,
  openedContentAccordionItems: PropTypes.object,
  closeOrOpenContentAccordionItem: PropTypes.func,
  loadHomepage: PropTypes.func,
};

export default Homepage;
