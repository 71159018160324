export function trackFoldInteraction(isBannerShown, tracking) {
  if (tracking) {
    if (isBannerShown) {
      return {
        googleTagManager: {
          containerId: window.gtmId,
          payload: { event: "sticky-fold-out" },
        },
      };
    }
    return {
      googleTagManager: {
        containerId: window.gtmId,
        payload: { event: "sticky-fold-in" },
      },
    };
  }
  return null;
}
